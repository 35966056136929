<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="video">
          <h1 class="title tittle-large">About Us.</h1>
          <p class="subtitle">
            With many years in the welding and custom fabrication industry, we have been using ESAB products. We have
            tried, tested, and pushed the ESAB equipiment to the max and wanted to represent them for our area.
          </p>
          <div class="video__frame">
            <img src="@/assets/images/welding.jpg" alt="welding" />
          </div>
        </div>
      </div>
    </section>
    <section class="section section--colored">
      <div class="container">
        <div class="block-wrap">
          <div class="block">
            <div class="block__info">
              <h2 class="block__title">Experienced Manufactures</h2>
              <p class="block__text">
                Coming from a family with multiple generations of manufacturing experience, we have tired and tested
                many welding supplies.
              </p>
              <p class="block__text">
                Based in Flesherton, Ontario, we serve the surrounding communities of Grey Highlands, Southgate,
                Singhampton, Dundalk, and more. Our goal is provide quick and easy solutions for manufactures and
                welding companies. We always stock a range of filler metals and other critical supplies for mild steel,
                stainless steel, and aluminum welding.
              </p>
            </div>
            <div class="block__img-wrap">
              <img src="@/assets/images/welding-about.jpg" alt="welding-about" class="block__img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  created() {
    window.scrollTo(0, 0)
  },
  metaInfo: {
    title: 'About | Flesherton Welding Supply',
    meta: [
      {
        name: 'description',
        content:
          'Family owned and operated business. We have a long history in welding and fabrication and stock high quality welding supply equipment.'
      }
    ]
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
